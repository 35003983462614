<template>
  <footer class="my-5">
    <hr />
    <p class="text-center">2023 &copy; Trisakti Akbar</p>
  </footer>
</template>

<script>
export default {
  name: "FooterComponent",
};
</script>

<style></style>
